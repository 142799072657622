import { useRef, useState, FormEvent } from "react";
import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";
import { HiOutlineCheck} from "react-icons/hi";
import { IconContext } from "react-icons";
import {
  HiOutlineEnvelope,
  HiOutlineChatBubbleLeftEllipsis,
} from "react-icons/hi2";

function ContactForm() {
  const form = useRef<HTMLFormElement>(null);

  // Introducing new states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  
  const handleInputChange = () => {
    if (isSuccess || isError) {
      setIsSuccess(false);
      setIsError(false);
    }
  };

  //emailjs.init(process.env.REACT_APP_PUBLICKEY!);

  async function onSubmit(e: FormEvent) {
    e.preventDefault();

    setIsLoading(true); // Set loading state

    emailjs
      .sendForm(
        "service_hewbylr",
        "template_9nnzhpu",
        form.current!,
        "RDzKdGiZ52NJm5A7i"
      )
      .then(
        (result: EmailJSResponseStatus) => {
          setIsLoading(false);
          setIsSuccess(true); // Set success state
          form.current!.reset(); // Clear the form
        },
        (error: any) => {
          setIsLoading(false);
          setIsError(true); // Set error state
        }
      );
  }

  return (
    <form ref={form} className="contact" onSubmit={(e) => onSubmit(e)}>
      <div className="container2 contfind">
        <IconContext.Provider value={{ className: "contactform-icons" }}>
          <button type="submit" className="btn_welcome">
            {isLoading ? (
              "Loading..." // Replace this with your loading component
            ) : isSuccess ? (
              <HiOutlineCheck /> // Check icon on success
            ) : isError ? (
             "ERROR"
            ) : (
              "Send"
            )}
          </button>
        </IconContext.Provider>
      </div>
      <div className="container containercontact">
        <h3>WHERE TO FIND ME</h3>
        <h4>I am here to help you...</h4>
        <div className="inputcontainer">
          <IconContext.Provider value={{ className: "input-icons" }}>
            <div className="emailcontainer">
              <div className="iconemail">
                <HiOutlineEnvelope />
              </div>
              <input
                type="email"
                name="reply_to"
                id="email"
                className="i_email"
                placeholder="Enter your email address..."
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                onChange={handleInputChange}
              ></input>
            </div>
            <div className="textcontainer">
              <div className="iconmessage">
                <HiOutlineChatBubbleLeftEllipsis />
              </div>
              <textarea
                className="i_text"
                name="message"
                id="message"
                placeholder="Type your message here..."
                onChange={handleInputChange}
              ></textarea>
            </div>
          </IconContext.Provider>
        </div>
      </div>
    </form>
  );
}

export default ContactForm;
