import "./home.css";
import Portrait from "../../assets/selfportrait.webp"
import Geometric1 from "../../assets/rock.svg";
import Geometric2 from "../../assets/spec.svg";
import Vector from "../../assets/vector.svg";
import Vector2 from "../../assets/vector2.svg";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { Link } from "react-router-dom";
import Contactform from "./contactform";

const scroll = () => {
  //@ts-ignore
  document.querySelector("#about").scrollIntoView({ behavior: "smooth", block: "start" });
};
function Home() {
  return (
    <div className="home">
      <div className="maincontainer">
        <div className="main">
          <img
            src={Geometric1}
            alt="geometric form background"
            className="geometric1"
          ></img>
          <img
            src={Geometric2}
            alt="geometric form background"
            className="geometric2"
          ></img>
          <div className="details">
            <div className="frame1">
              <img
                src={Portrait}
                alt="self portrait"
                className="portrait"
              ></img>
            </div>
            <h1>I am Lukas,</h1>
            <h2>
              Independent
              <br /> Frontend & Blockchain <br />
              Developer
            </h2>
            <button className="btn_mainmore" onClick={() => scroll()}>
              More about me
            </button>
          </div>
        </div>
      </div>
      <div className="information">
        <div className="welcome">
          <div className="container">
            <h3 id="about">ABOUT ME</h3>
            <h4>Welcome to my personal portfolio!</h4>
            <p>
              I am a frontend and blockchain developer with more than four
              years of experience and a strong understanding of the field's
              technical and financial concepts. <br />
              <br /> I have a broad range of skills, including expertise in
              blockchain-based technologies, financial analysis, and software
              development. The combination of technical and financial know-how
              allows me to understand and build complex DeFi applications or
              other kinds of smart contracts from the ground up.
            </p>
          </div>
          <div className="container2">
            <Link to="/portfolio">
              <button className="btn_welcome btn_seeM">Discover</button>
            </Link>
            <img
              src={Geometric1}
              alt="geometric form background"
              className="geometric1w"
            ></img>
          </div>
        </div>
        <img src={Vector} alt="divider" className="divider"></img>
        <img
          src={Geometric2}
          alt="geometric form background"
          className="geometric3"
        ></img>
        <GoogleReCaptchaProvider reCaptchaKey="6LczJFMmAAAAAC3br5m7NrrS0TMnHnuUfl6ZTRrz">
          <Contactform />
        </GoogleReCaptchaProvider>
        <img src={Vector2} alt="divider" className="divider2"></img>
      </div>
    </div>
  );
}

export default Home;
