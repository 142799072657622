import { useState } from "react";
import "./header.css";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import {
  HiHome,
  HiOutlineHome,
  HiBriefcase,
  HiOutlineBriefcase,
  HiAcademicCap,
  HiOutlineAcademicCap,
  HiBuildingOffice,
  HiOutlineBuildingOffice,
} from "react-icons/hi2";
import {
  HiOutlineMenu,
} from "react-icons/hi";

import { ReactElement } from "react";
import { useLocation } from "react-router-dom";

interface NavItemProps {
  selected: boolean;
  onSelect: () => void;
  onMouseLeave: () => void;
  icon: ReactElement;
  outlineIcon: ReactElement;
  label: string;
  link: string;
}

const NavItem: React.FC<NavItemProps> = ({
  selected,
  onSelect,
  onMouseLeave,
  icon,
  outlineIcon,
  label,
  link,
}) => (
  <Link to={link} className="linkheader">
    <li
      className={selected ? "selected" : ""}
      onMouseEnter={onSelect}
      onMouseLeave={onMouseLeave}
      onClick={onSelect}
    >
      {selected ? icon : outlineIcon}
      <span>{label}</span>
    </li>
  </Link>
);


function Header() {
  const [expand, setExpand] = useState<boolean>(false);
  let location = useLocation();
  const [selectedPage, setSelectedPage] = useState<string | null>("/");
  return (
    <nav>
      <IconContext.Provider value={{ className: "nav-icons" }}>
        <ul className="mobile" onClick={() => setExpand(!expand)}>
          <li>
            <HiOutlineMenu />
          </li>
        </ul>
        <ul className="menu" hidden={false}>
          {expand || window.innerWidth > 499 ? (
            <>
              <NavItem
                selected={selectedPage === "/"}
                onSelect={() => setSelectedPage("/")}
                onMouseLeave={() => setSelectedPage(location.pathname)}
                icon={<HiHome />}
                outlineIcon={<HiOutlineHome />}
                label="Home"
                link="/"
              />
              <NavItem
                selected={selectedPage === "/portfolio"}
                onSelect={() => setSelectedPage("/portfolio")}
                onMouseLeave={() => setSelectedPage(location.pathname)}
                icon={<HiBriefcase />}
                outlineIcon={<HiOutlineBriefcase />}
                label="Portfolio"
                link="/portfolio"
              />
              <NavItem
                selected={selectedPage === "/education"}
                onSelect={() => setSelectedPage("/education")}
                onMouseLeave={() => setSelectedPage(location.pathname)}
                icon={<HiAcademicCap />}
                outlineIcon={<HiOutlineAcademicCap />}
                label="Education"
                link="/education"
              />
              <NavItem
                selected={selectedPage === "/imprint"}
                onSelect={() => setSelectedPage("/imprint")}
                onMouseLeave={() => setSelectedPage(location.pathname)}
                icon={<HiBuildingOffice />}
                outlineIcon={<HiOutlineBuildingOffice />}
                label="Imprint"
                link="/imprint"
              />
            </>
          ) : (
            ""
          )}
        </ul>
      </IconContext.Provider>
    </nav>
  );
}

export default Header;
