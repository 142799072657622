import React from 'react';
import './App.css';
import LoadingIcons from "react-loading-icons";

import {  Route, Routes, Link , useLocation} from "react-router-dom";

import Header from "./components/Header/header";
import Home from './components/Home/home';
import Arrow from "./assets/arrow.svg";

import Signature from "./assets/signature.svg";

const Portfolio = React.lazy(() => import("./components/Portfolio/portfolio"));
const Education = React.lazy(() => import("./components/Education/education"));
const Imprint = React.lazy(() => import("./components/Imprint/imprint"));

export default function App(){
  return (
    <div className="App">
      <Header />
      <React.Suspense
        fallback={
          <>
          <div className="loadingScreen">
            <LoadingIcons.BallTriangle
              stroke="#DED6EE"
              fill="#DED6EE"
              strokeOpacity={1}
            />
          </div>
          </>
        }
      >
        <Routes>
          <Route index element={<Home />}/>
          <Route
            path="portfolio"
            element={<Portfolio />}
          />
          <Route path="education" element={<Education />} />
          <Route path="Imprint" element={<Imprint />} />
        </Routes>
        <Footer />
      </React.Suspense>
    </div>
  );
}

function Footer(){
  const Route = ["/","/portfolio","/education","/imprint","/"];
  const TextFooter = ["Portfolio", "Education", "Imprint", "Home"]
  let location = useLocation();

  return (
    <footer>
      <Link
        className="nextPage"
        to={Route[Route.indexOf(location.pathname) + 1]}
        aria-label={`Next page: ${
          TextFooter[Route.indexOf(location.pathname)]
        }`}
      >
        next
        <span className="mainlink">
          {TextFooter[Route.indexOf(location.pathname)]}
        </span>
      </Link>
      <img src={Arrow} alt="nextPage arrow" className="arrow"></img>
      <a
        href="https://anca.sellingdreams.ro"
        title="designer anca"
        className="linkd"
      >
        <img
          src={Signature}
          alt="designer anca signature"
          className="signature"
        ></img>
      </a>
    </footer>
  );
}


